<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-6'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='plain_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$errors.length}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <label for='plain_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-6'>
                    <div class='p-inputgroup'>
                        <Button :icon="authorsRefreshLoadingIcon" class="p-button-warning" @click='refreshAuthors'/>
                        <span class='p-float-label'>
                        <MultiSelect
                            id='authors'
                            v-model="v$.model.authors.$model"
                            :options="dd_authors"
                            :class="{'p-invalid': v$.model.authors.$errors.length}"
                            optionLabel="label"
                            dataKey="id"
                            :showToggleAll='false'
                            display="chip"/>
                        <label for='authors'>Autori</label>
                    </span>
                    </div>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-6  md:col-2'>
                    <span class='p-float-label'>
                        <InputNumber id='ord' mode='decimal' :useGrouping='false'
                                     v-model="model.ord"
                        />
                        <label for='ord'>Ordine</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.img"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.img' class='text-center'>
                        <img :src='model.img' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-4'>
                    <ColorPicker4b
                        id='pod_color'
                        title='Colore generale'
                        v-model='model.color'/>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Podcasts');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import DropdownAuthors from '../../../mixins/dd_authors';
import ColorPicker4b from '../../../components/4books/forms/ColorPicker4b';
import Uploader from '../../../mixins/uploader';

export default {
    data(){
        return {};
    },
    mounted(){
        this.model = this.row;
        this.prepareUploads(model.uploads, this.lang);
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Curatore creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Curatore modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    components: { ColorPicker4b },
    mixins: [
        Navigations,
        Notifications,
        DropdownAuthors,
        Uploader
    ]
};
</script>
