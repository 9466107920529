<template>
    <div v-if='model && !model.is_null' :key='"pods_t_"+lang'>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-5'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='t_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug = row.slug'/>
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug = sluggify(model.title)'/>
                        <label for='t_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$invalid && v$.model.title.$dirty}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='catchline'
                                   type='text'
                                   v-model="v$.model.catchline.$model"
                                   :class="{'p-invalid': v$.model.catchline.$invalid && v$.model.catchline.$dirty}"
                        />
                        <label for='catchline'>Catchline</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description'
                        :key='"cont_pods_body_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.guest_desc'
                        :key='"cont_pod_gd_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Descrizione per SEO</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.img"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.img' class='text-center'>
                        <img :src='model.img' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg2'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='alterImg'
                                   type='text'
                                   v-model="model.alter_img"
                        />
                        <label for='alterImg'>Immagine secondaria</label>
                    </span>
                    <div v-if='model.alter_img' class='text-center'>
                        <img :src='model.alter_img' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <RelatedArticles
                        :articles='model.articles'
                        @reorder='reorderedEpisodes'
                        :sortable='true'
                        @article-added='addArticle'
                        @article-removed='removeArticle'/>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Podcasts');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../libs/tools';
import model from './model';

import RelatedArticles from '../../../components/4books/RelatedArticles';

export default {
    data(){
        return {};
    },
    components: {
        RelatedArticles
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
        imgRepo(){
            const ret = [];
            if (this.model.repo && this.model.repo.length){
                this.model.repo.split("|").forEach(src => {
                    ret.push({ url: src });
                });
            }
            return ret;
        }
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        },
        'model.lastUpload'(n){
            if (!n) return;
            if (this.model.repo) this.model.repo += "|";
            this.model.repo += n;
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        reorderedEpisodes(ev){
            this.model.articles = ev.value;
        },
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return;
            }

            const episodes = [];
            const delta = 10;
            let n = 1;
            this.model.articles.forEach(art => {
                episodes.push({
                    ord: delta*n,
                    article_id: art.id
                });
                n++;
            });
            this.model.episodes = episodes;
            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model, this.parent_id)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.message) {
                    if (err.response.data.message.includes("podcast_episodes_u_aid_idx")){
                        const rxp = /{{([^}]+)}}/g;
                        let found = rxp.exec(err.response.data.message);          // an array to collect the strings that are found
                        if (!found) found = ['', ''];
                        err.response.data.message = found[1]+" appartiene già ad un altro podcast!"
                    }
                }

                this.ShowStandardError(err.response, 10000);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(model.parseTranslation(mod.translations[this.lang]), base);
                if (!this.model.articles) this.model.articles = base.articles;
                return;
            }

            this.model = base;
        },
        addArticle(art){
                this.model.articles.push(art)
        },
        removeArticle(art){
            this.model.articles = this.model.articles.filter(b => b.id !== art.id);
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: Number
        },
        parent_id: {
            type: Number
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ]
}
</script>
