import tools from '../../../libs/tools';
import { required, minLength } from '@vuelidate/validators'

const model = {
    id: 0,
    slug: "",
    img: "",
    color: "",
    authors: [],
    last_update: 0,
    translations: {}
};

const translation = {
    id: 0,
    slug: "",
    podcast_id: 0,
    last_update: 0,
    l: "",
    catchline: "",
    img: "",
    alter_img: "",
    description: "",
    guest_desc: "",
    publish_state: "",
    articles: [],
    episodes: [],
    title: ""
};

export default {
    ...tools.models.apiCommons(model, translation, "podcast_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            authors: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            title: { required },
            catchline: { required },
            publish_state: { required },
            articles: {
                required,
                minLength: minLength(1)
            }

        }
    },
    uploads: [
        { el: "uppyImg", target: "img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyImg2", target: "alter_img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
